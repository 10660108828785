import React from "react"
import Layout from "../components/common/layout/layout"
import PageBanner from "../components/common/misc/pagebanner"

const CheckoutSuccess = (props) => {
  const reloadPage = (e) => {
    e.preventDefault()
    window.location.assign("/app/profile")
  }

  console.log(props.location)

  const isTeacher = props.location.search === "?class"

  return (
    <Layout>
      <PageBanner title="Success!" />
      <div className="container has-text-centered pt-6 px-2">
        <p className="block">
          Thank you for your purchase! You are now subscribed to Shakespeare In
          Bits.
        </p>
        <p className={`block ${isTeacher ? '' : 'is-hidden'}`}>
          You have been sent an important email with instructions regarding your
          student login code. Please check your spam folders if you don't see this mail.
        </p>
        <p className="block">
          If your subscription information hasn't been updated, please log out
          and in again.
        </p>
        <div className="block py-5">
          <a
            className="button is-black"
            href="/"
            onClick={(e) => reloadPage(e)}
          >
            Continue
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default CheckoutSuccess
